import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

import "../assets/css/index.css"
import "../assets/css/pravilaNagradneIgre.css"

const PravilaNagradneIgrePage = () => {

	return(
		<Layout>
			<Seo title="Pravila nagradne igre" />
			
			<div className="kn-mlinar-pravila-title-container"><h1 className="kn-mlinar-title">Pravila nagradne igre</h1></div>
			<div className="kn-mlinar-pravila-content-container">
				<p className="kn-mlinar-breadcrumbs">Početna<span> / Pravila nagradne igre</span></p>
				<br/>
				<h3 className="kn-mlinar-subtitle">„Mlinar i Jamnicu spoji i super nagrade osvoji“</h3>
				<p className="kn-mlinar-paragraph">Prije sudjelovanja u nagradnoj igri molimo Vas da pročitate ova pravila!</p>
				<ol>
					<li>
						<h3 className="kn-mlinar-subtitle">ČLANAK 1. Organizator</h3>
						<ol>
							<li><p className="kn-mlinar-paragraph">Organizator ove nagradne igre pod nazivom „Mlinar i Jamnicu spoji i super nagrade osvoji“ je MLINAR pekarska industrija d.o.o. Radnička cesta 228c, 10 000 Zagreb OIB: 62296711978 (u nastavku Organizator).</p></li>
							<li><p className="kn-mlinar-paragraph">Nagradna igra priređuje se u cilju promocije proizvoda Organizatora.</p></li>
						</ol>
					</li>
					<li>
						<h3 className="kn-mlinar-subtitle">ČLANAK 2. Trajanje</h3>
						<ol>
							<li><p className="kn-mlinar-paragraph">Nagradna igra traje od 18.10.2021. do 31.10.2021.</p></li>
						</ol>
					</li>
					<li>
						<h3 className="kn-mlinar-subtitle">ČLANAK 3. Područje organiziranja i provođenja nagradne igre</h3>
						<ol>
							<li><p className="kn-mlinar-paragraph">Nagradna igra se organizira i provodi u svim prodavaonicama Organizatora u Hrvatskoj.</p></li>
						</ol>
					</li>
					<li>
						<h3 className="kn-mlinar-subtitle">ČLANAK 4. Pravila</h3>
						<ol>
							<li><p className="kn-mlinar-paragraph">Pravila nagradne igre bit će objavljena na Internet stranici www.mlinar.hr. Sudionik koji sudjeluje u nagradnoj igri prihvaća prava i obaveze iz ovih pravila.</p></li>
						</ol>
					</li>
					<li>
						<h3 className="kn-mlinar-subtitle">ČLANAK 5. Pravo sudjelovanja</h3>
						<ol>
							<li><p className="kn-mlinar-paragraph">Pravo sudjelovanja imaju svi kupci u prodavaonicama Organizatora.</p></li>
							<li><p className="kn-mlinar-paragraph">Pravo sudjelovanja nemaju zaposlenici Organizatora sa svojom užom obitelji.</p></li>
						</ol>
					</li>
					<li>
						<h3 className="kn-mlinar-subtitle">ČLANAK 6. Način sudjelovanja</h3>
						<ol>
							<li><p className="kn-mlinar-paragraph">U nagradnoj igri mogu sudjelovati sve osobe s prebivalištem na području Republike Hrvatske koji za vrijeme trajanja nagradne igre „Mlinar i Jamnicu spoji i super nagrade osvoji“ kupe bilo koji Mlinarov proizvod i proizvod iz asortimana Jamnica plus d.o.o. u jednoj od Mlinar prodavaonica.
							<br/>
							<br/>
							Kupci će za ostvarenu kupnju dobiti račun na kojem će biti vidljivo da su kupili Mlinarov proizvod Jamnica proizvod. Potrebno je osobne podatke (ime i prezime, telefon, e-mail adresa i kućna adresa) i broj računa unijeti na Mlinar web stranici u obrazac.</p></li>
						</ol>
					</li>
					<li>
						<h3 className="kn-mlinar-subtitle">ČLANAK 7. Odabir dobitnika</h3>
						<ol>
							<li><p className="kn-mlinar-paragraph">Izvlačenje dobitnika nagradne igre održat će se u srijedu, 3.11.2021., u prostorijama Organizatora nagradne igre, uz prisustvo tročlane komisije. Izvlačenje će ručno izvršiti jedan od članova komisije.
							<br/>
							<br/>
							Tijekom izvlačenja dobitnika nagrada, sačinit će se zapisnik koji će sadržavati imena i adrese dobitnika te će se provjeriti jesu li ispunjeni uvjeti igranja nagradne igre iz čl. 6 stavka 1 ovih pravila.</p></li>
							<li><p className="kn-mlinar-paragraph">Dobitnici nagrada bit će objavljeni na službenoj Facebook stranici Mlinara i web stranici 
							<br/>
							<br/>
							Tijekom izvlačenja dobitnika nagrada, sačinit će se zapisnik koji će sadržavati imena i adrese dobitnika te će se provjeriti jesu li ispunjeni uvjeti igranja nagradne igre iz čl. 6 stavka 1 ovih pravila.
							<br/>
							<br/>
							<a href="https://www.facebook.com/MlinarHrvatska/">https://www.facebook.com/MlinarHrvatska/</a>
							<br />
							<a href="https://www.mlinar.hr/hr/">https://www.mlinar.hr/hr/</a>
							<br />
							<br />
							Dobitnici nagrada bit će obaviješteni o dobitku u roku od osam (8) dana od dana izvlačenja.</p></li>
						</ol>
					</li>
					<li>
						<h3 className="kn-mlinar-subtitle">ČLANAK 8. Diskvalifikacija</h3>
						<ol>
							<li>
								<p className="kn-mlinar-paragraph">Organizator zadržava pravo u bilo kojem trenutku diskvalificirati sudionike u sljedećim slučajevima:
								<br/>
								<br/>
								- ako sudionik prekrši ova pravila
								<br/>
								- ako sudionik ne pošalje točan broj računa koji je određen ovim pravilima nagradne igre
								<br/>
								- ako sudionik upiše broj računa na kojem nisu navedeni proizvodi određeni pravilima nagradne igre
								<br/>
								- u drugim slučajevima za koje organizator procijeni da zahtijevaju diskvalifikaciju sudionika
								</p>
							</li>
						</ol>
					</li>
					<li>
						<h3 className="kn-mlinar-subtitle">ČLANAK 9. Nagradni fond</h3>
						<ol>
							<li>
								<p className="kn-mlinar-paragraph">Nagradni fond sastoji se od:
								<br/>
								<br/>
								1. Samsung Galaxy Z Fold 3 256GB crni 5G u vrijednosti 13.999,00 kn
								<br/>
								2. Samsung Galaxy Z Flip 3 128GB crni 5G u vrijednosti 7.699,00 kn
								<br/>
								3. Samsung Galaxy Watch4 Classic 42mm crni u vrijednosti 2.699,00 kn
								<br/>
								<br/>
								Ukupna vrijednost nagradnog fonda : 24.397,00 kn (uključen PDV)
								<br/>
								<br/>
								Organizator se obvezuje pet posto (5%) utvrđene vrijednosti nagradnog fonda uplatiti u korist Hrvatskog Crvenog križa.
								</p>
							</li>
							<li>
								<p className="kn-mlinar-paragraph">Sudionici u ovoj promotivnoj aktivnosti nemaju pravo zahtijevati drugu nagradu od one koja je navedena u ovim pravilima.</p>
							</li>
							<li>
								<p className="kn-mlinar-paragraph">Nagrada se ne može zamijeniti za novac, druge proizvode ili usluge, niti se mogu prenijeti na drugu osobu.</p>
							</li>
						</ol>
					</li>
					<li>
						<h3 className="kn-mlinar-subtitle">ČLANAK 10. Rezultati promotivne aktivnosti i preuzimanje nagrade</h3>
						<ol>
							<li>
								<p className="kn-mlinar-paragraph">Imena dobitnika nagrada, bit će objavljena na web stranici organizatora i Facebook profilu <a href="https://www.mlinar.hr/hr/">https://www.mlinar.hr/hr/</a> , <a href="https://www.facebook.com/Mlinar.Hrvatska/">https://www.facebook.com/Mlinar.Hrvatska/</a>. Svi dobitnici bit će obaviješteni o načinu preuzimanja nagrada telefonski u roku od osam (8) dana od dana izvlačenja.</p>
							</li>
							<li>
								<p className="kn-mlinar-paragraph">Nagrade dobitnicima biti će poslane poštom na kućne adrese u roku od 30 dana od dana primitka obavijesti o dobivenoj nagradi.</p>
							</li>
							<li>
								<p className="kn-mlinar-paragraph">Preuzimanjem nagrade prestaju sve obveze Organizatora prema dobitniku.</p>
							</li>
						</ol>
					</li>
					<li>
						<h3 className="kn-mlinar-subtitle">ČLANAK 11. Publicitet</h3>
						<ol>
							<li>
								<p className="kn-mlinar-paragraph">Za potrebe promocije nagradne igre, Organizator će objaviti podatke o dobitnicima (njihova imena) te podatke o osvojenim nagradama. Također, Organizator ima pravo dobitnike pozvati na javno uručenje nagrade. Na javnom uručenju nagrade, dobitnici mogu biti uslikani ili snimljeni, a takve snimke odnosno videozapisi javno objavljeni - opet za potrebe promocije nagradne igre. Više o tome kako Organizator obrađuje osobne podatke sudionika i dobitnika možete saznati na <a href="http://www.mlinar.hr/hr/pravila-privatnosti">http://www.mlinar.hr/hr/pravila-privatnosti</a>.</p>
							</li>
						</ol>
					</li>
					<li>
						<h3 className="kn-mlinar-subtitle">ČLANAK 12. Prekid promotivne aktivnosti</h3>
						<ol>
							<li>
								<p className="kn-mlinar-paragraph">Nagradna igre se može prekinuti samo u slučaju da nastupe okolnosti za koje Organizator nije odgovoran, odnosno koje nije mogao spriječiti, otkloniti ili izbjeći. U tom slučaju se oslobađa svih eventualno nastalih obveza prema sudionicima. Sudionici će o prekidu nagradne igre biti obaviješteni putem elektroničkih medija.</p>
							</li>
						</ol>
					</li>
					<li>
						<h3 className="kn-mlinar-subtitle">ČLANAK 13. Porezi</h3>
						<ol>
							<li>
								<p className="kn-mlinar-paragraph">Organizator ne snosi nikakve eventualne poreze, obveze ili naknade izravno povezane s nagradom.</p>
							</li>
						</ol>
					</li>
					<li>
						<h3 className="kn-mlinar-subtitle">ČLANAK 14. Prihvaćanje pravila</h3>
						<ol>
							<li>
								<p className="kn-mlinar-paragraph">Sudionik nagradne igre prihvaća prava i obveze iz ovih Pravila.</p>
							</li>
							<li>
								<p className="kn-mlinar-paragraph">Organizator zadržava pravo promjene ovih Pravila.</p>
							</li>
							<li>
								<p className="kn-mlinar-paragraph">O promjenama u pravilima organizator će obavijestiti sudionike putem elektroničkih Medija.</p>
							</li>
						</ol>
					</li>
					<li>
						<h3 className="kn-mlinar-subtitle">ČLANAK 15. Tumač pravila</h3>
						<ol>
							<li>
								<p className="kn-mlinar-paragraph">Glavni tumač ovog pravilnika je Organizator promotivne aktivnosti.</p>
							</li>
						</ol>
					</li>
					<li>
						<h3 className="kn-mlinar-subtitle">ČLANAK 16. Pravna nadležnost</h3>
						<ol>
							<li>
								<p className="kn-mlinar-paragraph">Ugovorne stranke su suglasne da će sve eventualne međusobne sporove proizašle iz ovog Pravilnika pokušati riješiti sporazumno, a ako to nije moguće, ugovaraju nadležnost stvarno nadležnog suda u Zagrebu.</p>
							</li>
						</ol>
					</li>
					<li>
						<h3 className="kn-mlinar-subtitle">ČLANAK 17. Valjanost pravila nagradne igre</h3>
						<ol>
							<li>
								<p className="kn-mlinar-paragraph">Ova pravila primjenjuju se nakon što Ministarstvo financija RH na njih izda odobrenje, a vrijede sve do završetka nagradne igre.</p>
							</li>
							<li>
								<p className="kn-mlinar-paragraph">Nagradna igra odobrena je od Ministarstva financija pod brojem:
								<br/>
								<br/>
								KLASA: UP/I-460-02/21-01/426
								<br/>
								URBROJ: 513-07-21-01/21-2
								</p>
							</li>
						</ol>
					</li>
				</ol>
				<p className="kn-mlinar-paragraph">
				Priređivač nagradne igre:
				<br/>
				<br/>
				Mlinar pekarska industrija d.o.o.
				<br/>
				Radnička cesta 228c
				<br/>
				10 000 Zagreb
				<br/>
				Tel: 01/ 2382 300
				<br/>
				Fax: 01/ 2382 303
				<br/>
				OIB: 62296711978
				</p>
			</div>
		</Layout>
	)
}
export default PravilaNagradneIgrePage
